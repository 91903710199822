export default class Modal {
    init() {
        $(document).on('toggle', '.arkonsizetables-modal', (e) => {
            this.onToggle(e);
        });

        $(document).on(
            'click',
            '[data-arkonsizetables-modal-selector]',
            (e) => {
                this.onClick(e);
            }
        );

        $(document).on('click', '.arkonsizetables-modal', (e) => {
            this.onClose(e);
        });

        $(document).on('keyup', (e) => {
            this.onKeyUp(e);
        });

        $(document).on('ready', () => {
            this.onReady();
        });
    }

    onToggle(e) {
        const modal = $('.arkonsizetables-modal');

        if (modal.hasClass('active')) {
            this.hideModal(modal);
        } else {
            this.showModal(modal);
        }
    }

    /* Click on show modal button */
    onClick(e) {
        e.preventDefault();
        const modalSelector = $(e.target).data(
            'arkonsizetables-modal-selector'
        );
        $(modalSelector).trigger('toggle');
    }

    /* Triggers close of modal */
    onClose(e) {
        const target = $(e.target);
        if (target.hasClass('arkonsizetables-modal')) {
            target.trigger('toggle');
        }
    }

    /* Triggers close on esc key */
    onKeyUp(e) {
        if (e.keyCode === 27) {
            const modal = $('.arkonsizetables-modal.active');
            if (modal.length < 1) {
                return;
            }

            modal.trigger('toggle');
        }
    }

    onReady(e) {
    }

    showModal(modal) {
        if (modal.length < 1) {
            return;
        }
        modal.addClass('active');
        $('body').addClass('no-scroll');
    }

    hideModal(modal) {
        if (modal.length < 1) {
            return;
        }
        modal.addClass('animation-out');
        $('body').removeClass('no-scroll');

        setTimeout(() => {
            modal.removeClass('active');
            modal.removeClass('animation-out');
        }, 400);
    }
}
